<template>
    <v-list class="mx-8 my-2">

      <v-list-item>
        <v-divider :thickness="7"></v-divider>
        <v-card text="test">
          <v-card-title class="v-card-news">
            2024 - The New Era of AI
          </v-card-title>

        </v-card>
        <v-divider :thickness="7"></v-divider>
      </v-list-item>

      <v-row class="mx+n10">
          <v-col cols="12" md="6" class="d-flex py-0 px-2">
            <v-card
              class="mx-auto mt-10 flex-card"
              max-width="700"
              min-width="250"
            >
              <div class="image-container">
                <v-img
                  src="officerocket.webp"
                  height="250px"
                ></v-img>
              </div>

              <div class="flex-grow-1">
                <div class="title-and-icon-container">

                  <div class="title-subtitle-container">
                    <v-card-title class="v-card-title">
                      Office Rocket
                    </v-card-title>
                    <v-card-subtitle>
                      ESP32 | Neopixel
                    </v-card-subtitle>
                  </div>
                  
                  <div class="icon-container">
                    <v-img
                      src="date_2023.webp"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </div>

                </div>



                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                  >
                    Explore Intention
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="show11 = !show11"
                  >
                    <v-icon>{{ show11 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show11">
                    <v-divider></v-divider>
                    <br>
                    <h5 class="pl-4">Beschreibung</h5>
                    <v-card-text>
                      Ich habe bemerkt, dass mein Vorhaben am Arbeitsplatz länger zu Stehen ständig von einer unterbewussten
                      Bequemlichkeit torpediert wird. Kaum aufgestanden fährt der Tisch wenige Minuten später ganz automatisch wieder nach unten.
                      Eine Lösung musste her und so habe ich mir einen kleinen Motivator namens Office Rocket geschaffen.
                      Diese kleine Rackete misst kontinuierlich meine Position und verändert die Farbe Ihrer Triebwerke von grün über orange zu rot, sollte ich zu lange Sitzen.
                      Mission accomplished!

                      Die Office Rocket kann aber noch mehr und fungiert auch als "Pomodorro Timer" (Googeln ;-))
                      Die Rackete ist als Buzzer umgesetzt und mit einem Klapps auf den Deckel aktiviert man die Pomodorro Session.
                      Auf der Unterseite ist ein Drehencoder angebracht mit dem man diverse Parameter in einem Menü einstellen kann. 

                    </v-card-text>
                    <h5 class="pl-4">Motivation</h5>
                    <v-card-text>
                      UX Design umsetzen | etwas Ästhetisches schaffen | Multithreading | State Machine
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </div>
              
            </v-card>
          </v-col>

          <v-col cols="12" md="6" class="d-flex py-0 px-2">
            <v-card
              class="mx-auto mt-10 flex-card"
              max-width="700"
              min-width="250"
            >
              <div class="image-container">
                <v-img
                  src="konfetti.webp"
                  height="250px"
                ></v-img>
              </div>

              <div class="flex-grow-1">
                <div class="title-and-icon-container">

                  <div class="title-subtitle-container">
                    <v-card-title class="v-card-title">
                      Hochzeit Konfettikanone
                    </v-card-title>
                    <v-card-subtitle>
                      Arduino
                    </v-card-subtitle>
                  </div>
                  
                  <div class="icon-container">
                    <v-img
                      src="date_2023.webp"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </div>

                </div>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                  >
                    Explore Intention
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="show10 = !show10"
                  >
                    <v-icon>{{ show10 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show10">
                    <v-divider></v-divider>
                    <br>
                    <h5 class="pl-4">Beschreibung</h5>
                    <v-card-text>
                      Eine Überraschung für das Brautpaar musste her. Die Idee: Eine kleine "Mission Impossible".
                      Konfettikanonen kannte das Brautpaar allzugut, hatte es selbst doch diebische Freude daran in anderen 
                      Haushalten für Unordnung zu sorgen. Etwas Würze musste her und so entstand ein Rätsel, bei dem 
                      das Brautpaar die Chance hatte die durch Bewegungsmelder und Beschleunigungssensoren aktivierte Konfettikanone
                      mithilfe einer Fernbedienung und korrekten Code zu entschärfen. 
                    </v-card-text>
                    <h5 class="pl-4">Motivation</h5>
                    <v-card-text>
                      Brautpaar überraschen
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </div>
              
            </v-card>
          </v-col>
      </v-row>

      <v-row class="mx+n10">
          <v-col cols="12" md="6" class="d-flex py-0 px-2">
            <v-card
              class="mx-auto mt-10 flex-card"
              max-width="700"
              min-width="250"
            >
              <div class="image-container">
                <v-img
                  src="gps_tracker_website.webp"
                  height="250px"
                ></v-img>
              </div>

              <div class="flex-grow-1">
                <div class="title-and-icon-container">

                  <div class="title-subtitle-container">
                    <v-card-title class="v-card-title">
                      GPS Tracker With Accelerometer
                    </v-card-title>
                    <v-card-subtitle>
                      Arduino | IMU | GPS und GSM
                    </v-card-subtitle>
                  </div>
                  
                  <div class="icon-container">
                    <v-img
                      src="date_2023.webp"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </div>

                </div>



                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                  >
                    Explore Intention
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="show9 = !show9"
                  >
                    <v-icon>{{ show9 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show9">
                    <v-divider></v-divider>
                    <br>
                    <h5 class="pl-4">Beschreibung</h5>
                    <v-card-text>
                      Ausgangspunkt war der Wunsch einen GPS Tracker ganz nach meinen eigenen Vorstellungen zu entwickeln.
                      Die stabile Kommunikation mit unterschiedlichsten Modulen war die eigentliche Herausforderung dabei.
                      Am Ende steht nun aber dieser Tracker, der sich in einer Trinkflasche versteckt und mithilfe eines Beschleunigungssensors
                      aktiviert wird. Verschiedene Funktionsmodi ermöglichen es, individuelle Zeitintervalle für die Lokalisierung festzulegen.
                      Diese Intervalle nutze ich gleichzeitig um das System immer wieder in den Schlaf zu befördern und eine möglichst lange
                      Batterielaufzeit zu ermöglichen. Gesteuert werden kann das Ganze über SMS Befehlen. Vier zeitlich leicht versetzte 
                      Koordinatenpaare werden jeweils als SMS verschickt und können dann in Google Maps lokalisiert werden.
                    </v-card-text>
                    <h5 class="pl-4">Motivation</h5>
                    <v-card-text>
                      wieder Basteln | mit Hardware Registern kommunizieren | Low Power Anwendung realisieren | State Machine | SMS Steuerung | Idee -> Machen
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </div>
              
            </v-card>
          </v-col>

          <v-col cols="12" md="6" class="d-flex py-0 px-2">
            <v-card
              class="mx-auto mt-10 flex-card"
              max-width="700"
              min-width="250"
            >
              <div class="image-container">
                <v-img
                  src="backend.webp"
                  height="250px"
                ></v-img>
              </div>

              <div class="flex-grow-1">
                <div class="title-and-icon-container">

                  <div class="title-subtitle-container">
                    <v-card-title class="v-card-title">
                      First Backend As Machine Learning Microservice
                    </v-card-title>
                    <v-card-subtitle>
                      FastAPI | Docker | Postgres SQL | SQLAlchemy | Uvicorn | Heroku
                    </v-card-subtitle>
                  </div>
                  
                  <div class="icon-container">
                    <v-img
                      src="date_2022.webp"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </div>

                </div>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                  >
                    Explore Intention
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="show8 = !show8"
                  >
                    <v-icon>{{ show8 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show8">
                    <v-divider></v-divider>
                    <br>
                    <h5 class="pl-4">Beschreibung</h5>
                    <v-card-text>
                      Mithilfe von FastAPI habe ich meine erste API umgesetzt. Ziel war es u.a. die Vorhersage des Neuronalen Netzes
                      in der Cloud für authentifizierte Personen zugänglich zu machen. Die gehasten Passwörter wurden in einer PostgresSQL Datenbank abgelegt.
                      Ein sicherer Zugang habe ich via OAuth2 sichergestellt. Weitere API Endpoints wurden für die Anwendung integriert.
                      Anschließend wurde die API mit Tensorflow Modell in einen Docker Container gepackt und auf Heroku deployed.
                    </v-card-text>
                    <h5 class="pl-4">Motivation</h5>
                    <v-card-text>
                      Produktives Backend umsetzen| Neuronales Netz als Microservice bereitstellen | Docker Applikationen erstellen | Anwendung deployen
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </div>
              
            </v-card>
          </v-col>
      </v-row>

      <v-row class="mx+n10">
          <v-col cols="12" md="6" class="d-flex py-0 px-2">
            <v-card
              class="mx-auto mt-10 flex-card"
              max-width="700"
              min-width="250"
            >
              <div class="image-container">
                <v-img
                  src="tensorboard.webp"
                  height="250px"
                ></v-img>
              </div>

              <div class="flex-grow-1">
                <div class="title-and-icon-container">

                  <div class="title-subtitle-container">
                    <v-card-title class="v-card-title">
                      My First Neural Network
                    </v-card-title>
                    <v-card-subtitle>
                      Tensorflow | Keras | Data Preprocessing | TF Dataset | Hyperparameter Optimizing | Feature Design
                    </v-card-subtitle>
                  </div>
                  
                  <div class="icon-container">
                    <v-img
                      src="date_2022.webp"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </div>

                </div>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                  >
                    Explore Intention
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="show7 = !show7"
                  >
                    <v-icon>{{ show7 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show7">
                    <v-divider></v-divider>
                    <br>
                    <h5 class="pl-4">Beschreibung</h5>
                    <v-card-text>
                      Ich habe mich in die Tiefen der Neuronalen Netze begeben und ein eigenes neuronales Netz kreiert und trainiert.
                      Gegenstand war die Klassifizierung zweier Bewegungsmuster für ein mechatronisches Endgerät. Bei etwas über 1000 erhobenen Datenpunkten hatte das finale Modell eine Vorhersagegenauigkeit von 99% auf dem Testdatenset (kein nennenswertes Overfitting)
                    </v-card-text>
                    <h5 class="pl-4">Motivation</h5>
                    <v-card-text>
                      Eigene Neuronale Netze umsetzen | professionellen Tensorflow Workflow umsetzen | Coding/ Preprocessing | Optimizing
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </div>
              
            </v-card>
          </v-col>

          <v-col cols="12" md="6" class="d-flex py-0 px-2">
            <v-card
              class="mx-auto mt-10 flex-card"
              max-width="700"
              min-width="250"
            >
              <div class="image-container">
                <v-img
                  src="webapp.webp"
                  height="250px"
                ></v-img>
              </div>

              <div class="flex-grow-1">
                <div class="title-and-icon-container">

                  <div class="title-subtitle-container">
                    <v-card-title class="v-card-title">
                      My First Webapp
                    </v-card-title>
                    <v-card-subtitle>
                      Vue.js | Vuetify | Firebase | Git
                    </v-card-subtitle>
                  </div>
                  
                  <div class="icon-container">
                    <v-img
                      src="date_2022.webp"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </div>

                </div>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                  >
                    Explore Intention
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="show6 = !show6"
                  >
                    <v-icon>{{ show6 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show6">
                    <v-divider></v-divider>
                    <br>
                    <h5 class="pl-4">Beschreibung</h5>
                    <v-card-text>
                      Hier bleibt nicht viel zu sagen :-). Meine erste Webapp habt ihr als Ergebnis direkt vor euch. Natürlich könnte man so etwas viel besser über einen Webbaukasten realisieren... Ich war aber zu neugierig und wollte in die Konzepte und Arbeitsweisen von WebDevs eintauchen. Viel Spaß weiterhin beim Stöbern in meinen kleinen Projekten.
                    </v-card-text>
                    <h5 class="pl-4">Motivation</h5>
                    <v-card-text>
                      Spaß haben | WebDev kennenlernen | Vue.js/ Vuetify kennenlernen | Git Prozess kennenlernen | Firebase Hosting
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </div>
              
            </v-card>
          </v-col>
      </v-row>

      <v-row class="mx+n10">
          <v-col cols="12" md="6" class="d-flex py-0 px-2">
            <v-card
              class="mx-auto mt-10 flex-card"
              max-width="700"
              min-width="250"
            >
              <div class="image-container">
                <v-img
                  src="bananatracker.webp"
                  height="250px"
                ></v-img>
              </div>

              <div class="flex-grow-1">
                <div class="title-and-icon-container">

                  <div class="title-subtitle-container">
                    <v-card-title class="v-card-title">
                      Banana Tracker
                    </v-card-title>
                    <v-card-subtitle>
                      Raspberry Pi | OpenCV | TensorFlowLite | Servo
                    </v-card-subtitle>
                  </div>
                  
                  <div class="icon-container">
                    <v-img
                      src="date_2021.webp"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </div>

                </div>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                  >
                    Explore Intention
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="show2 = !show2"
                  >
                    <v-icon>{{ show2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show2">
                    <v-divider></v-divider>
                    <h5 class="pl-4">Beschreibung</h5>
                    <v-card-text>
                      In diesem kleinen Projekt verfolgt eine hungrige Kamera eine Banane. Die Kamera wird von zwei Servos positioniert und über einen Raspberry Pi angesteuert. Mithilfe von TensorFlowLite und dem TensorFlow Object Detection Model wurde die Objekterkennung realisiert. 
                    </v-card-text>
                    <h5 class="pl-4">Motivation</h5>
                    <v-card-text>
                      Spaß haben | TensorFlow auf Raspi testen | Servoansteuerung mit Raspi
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </div>
              
            </v-card>
          </v-col>

          <v-col cols="12" md="6" class="d-flex py-0 px-2">
            <v-card
              class="mx-auto mt-10 flex-card"
              max-width="700"
              min-width="250"
            >
              <div class="image-container">
                <v-img
                  src="picturewheel.webp"
                  height="250px"
                ></v-img>
              </div>

              <div class="flex-grow-1">
                <div class="title-and-icon-container">

                  <div class="title-subtitle-container">
                    <v-card-title class="v-card-title">
                      Picture Wheel
                    </v-card-title>
                    <v-card-subtitle>
                      ESP32 | Firebase App | Floppy Disc Motor
                    </v-card-subtitle>
                  </div>
                  
                  <div class="icon-container">
                    <v-img
                      src="date_2021.webp"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </div>

                </div>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                  >
                    Explore Intention
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="show3 = !show3"
                  >
                    <v-icon>{{ show3 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show3">
                    <v-divider></v-divider>
                    <h5 class="pl-4">Beschreibung</h5>
                    <v-card-text>
                      Hier seht ihr die Mechatronik eines etwas anderen Bilderrahmens. Dieser Bilderrahmen transformiert sich quasi selbständig über die Zeit, denn wer will immer die gleichen Fotos an der Wand sehen? Das Displayleuchten eines digitalen Bilderrahmens fand ich unschön und so entsand die Idee eines mechanischen Bilderrahmens, der selbst wie eine Uhr rotiert und über die Zeit, die angepinnten Fotos "auf 12 Uhr" einsammelt. Magazin voll? Zeit für neue Fotos und neue Erinnerungen.     
                    </v-card-text>
                    <h5 class="pl-4">Motivation</h5>
                    <v-card-text>
                      Produkt UX schaffen | Elektronikbastelei | Steuerung via App | Prototypen
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </div>
              
            </v-card>
          </v-col>
      </v-row>

      <v-row class="mx+n10">
          <v-col cols="12" md="6" class="d-flex py-0 px-2">
            <v-card
              class="mx-auto mt-10 flex-card"
              max-width="700"
              min-width="250"
            >
              <div class="image-container">
                <v-img
                  src="lightstick.webp"
                  height="250px"
                ></v-img>
              </div>

              <div class="flex-grow-1">
                <div class="title-and-icon-container">

                  <div class="title-subtitle-container">
                    <v-card-title class="v-card-title">
                      Light Painting
                    </v-card-title>
                    <v-card-subtitle>
                      Arduino | Pixel LED
                    </v-card-subtitle>
                  </div>
                  
                  <div class="icon-container">
                    <v-img
                      src="date_2018.webp"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </div>

                </div>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                  >
                    Explore Intention
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="show4 = !show4"
                  >
                    <v-icon>{{ show4 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show4">
                    <v-divider></v-divider>
                    <h5 class="pl-4">Beschreibung</h5>
                    <v-card-text>
                      Diese kleinen Filmhelden existieren nur virtuell vor Münchens Kulisse. Einem LED Pixelstreifen wurden im Zeitverlauf je eine Spalte eines Bildes übergeben. Mittels Langzeitbelichtung und Bewegung des LED Pixelstabes, lies sich dieses Bild kreieren.     
                    </v-card-text>
                    <h5 class="pl-4">Motivation</h5>
                    <v-card-text>
                      Arduino Spaß | LED Pixel kennenlernen | Spaß mit Freunden
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </div>
              
            </v-card>
          </v-col>

          <v-col cols="12" md="6" class="d-flex py-0 px-2">
            <v-card
              class="mx-auto mt-10 flex-card"
              max-width="700"
              min-width="250"
            >
              <div class="image-container">
                <v-img
                  src="virtualhug.webp"
                  height="250px"
                ></v-img>
              </div>

              <div class="flex-grow-1">
                <div class="title-and-icon-container">

                  <div class="title-subtitle-container">
                    <v-card-title class="v-card-title">
                      Virtual Hug
                    </v-card-title>
                    <v-card-subtitle>
                      Arduino | Old Phone
                    </v-card-subtitle>
                  </div>
                  
                  <div class="icon-container">
                    <v-img
                      src="date_2015.webp"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </div>

                </div>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                  >
                    Explore Intention
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="show5 = !show5"
                  >
                    <v-icon>{{ show5 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show5">
                    <v-divider></v-divider>
                    <h5 class="pl-4">Beschreibung</h5>
                    <v-card-text>
                      Hier seht ihr eines meiner ersten Mechatronik Projekte. Die Idee bestand darin, einer nahestehenden Person über Distanz einen originellen Gruß zu senden. Über einen Anruf wurde für eine gewisse Zeit ein LED Leuchtring aktiviert, der eine Blumenvase für eine gewisse Zeit angeleuchtet hat. Umgesetzt habe ich das damals mit einfachen Mitteln. An einem alten Handy wurde die Spannung des Vibrationsmotors von einem Arduino ausgelesen, der dann wiederum die dezente Beleuchtung für ein paar Minuten aktiviert hat.
                    </v-card-text>
                    <h5 class="pl-4">Motivation</h5>
                    <v-card-text>
                      Arduino Nano | Altes Handy | Freude bereiten
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </div>
              
            </v-card>
          </v-col>
      </v-row>

      <v-list-item>
        <v-card
          class="mx-auto mt-10"
          max-width="700"
          min-width="250"
        >
          <v-img
            src="lost.webp"
            height="250px"
          ></v-img>

          <v-card-title>
            Other Projects
          </v-card-title>

          <v-card-subtitle>
            Arduino | Raspberry Pi | Machine Learning | 3D Printing | Mechanical Engineering
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
              color="orange lighten-2"
              text
            >
              Explore Intention
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              icon
              @click="show6 = !show6"
            >
              <v-icon>{{ show6 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show6">
              <v-divider></v-divider>
              <h5 class="pl-4">Weitere Projekte</h5>
              <v-card-text>
                <ul>
                  <li>Self Balancing Robot</li>
                  <li>Ball Balancing PID System</li>
                  <li>Creating Shirt Designs</li>
                  <li>Lot's of mechanical Stuff</li>
                </ul>        
              </v-card-text>
              <h5 class="pl-4">Motivation</h5>
              <v-card-text>
                Spaß haben | Dinge selbst tun | Neues Lernen
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-list-item>

    </v-list>
</template>

<style scoped>

.v-card-news {
  color: #828282; /* Setzt die Schriftfarbe auf #E5E5E5 */
  font-size: 14px;
}

.title-and-icon-container {
  display: flex; /* Flex-Container */
  align-items: center; /* Zentriert die Elemente vertikal */
}

.title-subtitle-container {
  flex-grow: 1; /* Ermöglicht es dem Titel- und Untertitelcontainer, den verfügbaren Platz auszufüllen */
}

.icon-container {
  flex-shrink: 0; /* Verhindert das Schrumpfen des Icon-Containers */
  /* Feste Größe für das Dreiecksbild */
  height: 100px;
  width: 100px;
}

.v-card-title {
  flex-grow: 1;
  word-break: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
}

.flex-card {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.image-container {
  height: 250px; /* Feste Höhe für den Bildcontainer */
  overflow: hidden; /* Verhindert das Überlaufen des Inhalts außerhalb des Containers */
}

.flex-card .v-img {
  flex-shrink: 0; /* Verhindert das Schrumpfen des Bildes */
  flex-grow: 0;   /* Verhindert das Wachsen des Bildes */
}

.flex-grow-1 {
  flex-grow: 1; /* Lässt den Textbereich den verfügbaren Platz ausfüllen */
}

</style>

<script>
  

  export default {
    name: 'Home',
    
    
    show2: false,
    data: () => ({
      show: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
      show10: false,
      show11: false,
    }),

  }


</script>
